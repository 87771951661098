import React, { useState, useEffect, useRef, useCallback } from 'react';
import clsx from 'clsx';

import { dispatcherWebRTCManager } from '../../webrtc/webrtcManagers/DispatcherWebRTCManager';

// import OpenStreetmap from './OpenStreetmap';
// import SystemPanel from './SystemPanel';
// import GeoPanel from './GeoPanel';
import DispatcherNavbar from './DispatcherNavbar';
import GridBox from '../Globals/GridBox';
// import WebRTCSwitcher from './WebRTCSwitcher';
// import ChatViewDispatcher from '../Chat/ChatViewDispatcher';
// import CameraChooser from './CameraChooser';
import SystemFooter from '../Globals/SystemFooter';

import './DispatcherDashboard.scss';
import { addLogDispatch } from '../../redux/actions/logs';
import {
    deactivateVideoDispatcherDispatch,
    deactivateChatDispatcherDispatch,
    deactivateGPSDispatch,
    deactivateSnapshotDispatch,
    disableSnapshotDispatch,
    disablePointerDispatch,
    deactivatePointerDispatcherDispatch,
    deactivateHDSendDispatch,
    deactivateDrawDispatcherDispatch,
    disableDrawDispatch,
} from '../../redux/actions/application';

import { DEBUG, DEFAULT_RECORDING_TTL } from '../../config';
import { replaceText, setDispatcherLanguage, setWhiteLabelLogo } from '../../helper/helper';
import { SMSGuide } from './SMSGuide';
import TimeoutOverlay from './TimeoutOverlay';
// import BidiPanel from './BidiPanel';
// import NotesPanel from './NotesPanel';
import { getCurrentDispatchCenterConfig, getImage } from '../../api/backendApi';
import { dispatchAddDispatchCenterTtl, dispatchAddVideoBackgroundImage } from '../../redux/actions/session';
// import ScreensharePanel from './ScreensharePanel';
import {
    dispatchActivateFocusControls,
    dispatchResetFocusWindow,
    dispatchSetFocusWindowBidi,
    dispatchSetFocusWindowChat,
    dispatchSetFocusWindowExternalStream,
    dispatchSetFocusWindowLiveVideo,
    dispatchSetFocusWindowScreenshare,
} from '../../redux/actions/focus';
import { imageEndpointType, WebRtcManagerType, WebRtcMessageDispatcher } from '../../types';
// import { MediaGallery } from './MediaGallery';
// import { InvitationPanel } from './InvitationPanel';
// import ExternalStreamPanel from './ExternalStreamPanel';
// import { VideoContainerDispatcher } from '../Video/VideoContainerDispatcher';
// import SmartConnect from './SmartConnect';
import {
    sendAudioActivationStatusFromStore,
    sendSessionData,
    sendSessionHandlingData,
    sendSetFeatureFocus,
} from '../../webrtc/outgoingMessages/outgoingMessagesDispatcher';
import { FOCUS_FEATURE_TYPE } from '../../types/focus.types';
import store, { RootState } from '../../redux/store';
import { resetSessionHandlingData } from '../../redux/slices/sessionHandlingSlice';
import { useSelector } from 'react-redux';
import ChatViewDispatcher from '../Chat/ChatViewDispatcher';

const DispatcherDashboard = () => {
    const snapshotFeature = useSelector((state: RootState) => state.features.snapshotFeature);
    const hdSendFeature = useSelector((state: RootState) => state.features.hdSendFeature);
    const chatFeature = useSelector((state: RootState) => state.features.chatFeature);
    const smsGuideFeature = useSelector((state: RootState) => state.features.smsGuideFeature);
    const pointerFeature = useSelector((state: RootState) => state.features.pointerFeature);
    const texts = useSelector((state: RootState) => state.texts.texts);
    const gpsIsActive = useSelector((state: RootState) => state.application.gpsIsActive);
    const drawIsActive = useSelector((state: RootState) => state.application.drawIsActive);
    const videoIsActive = useSelector((state: RootState) => state.application.videoIsActive);
    const chatIsActive = useSelector((state: RootState) => state.application.chatIsActive);
    const hdSendIsActive = useSelector((state: RootState) => state.application.hdSendIsActive);
    const snapshotIsActive = useSelector((state: RootState) => state.application.snapshotIsActive);
    const pointerIsActive = useSelector((state: RootState) => state.application.pointerIsActive);
    const drawFeature = useSelector((state: RootState) => state.features.drawFeature);
    const audioStreamFeature = useSelector((state: RootState) => state.features.audioStreamFeature);
    const audioStreamIsActive = useSelector((state: RootState) => state.application.audioStreamIsActive);
    const bidiFeature = useSelector((state: RootState) => state.features.bidiFeature);
    const bidiIsActive = useSelector((state: RootState) => state.application.bidiIsActive);
    const notesFeature = useSelector((state: RootState) => state.features.notesFeature);
    const streamRecordingFeature = useSelector((state: RootState) => state.features.streamRecordingFeature);
    const streamRecordingIsActive = useSelector((state: RootState) => state.application.streamRecordingIsActive);
    const screenShareFeature = useSelector((state: RootState) => state.features.screenShareFeature);
    const screenshareIsActive = useSelector((state: RootState) => state.application.screenshareIsActive);
    const currentFocusFeature = useSelector((state: RootState) => state.focus.currentFocusFeature);
    const focusControlsAreActive = useSelector((state: RootState) => state.focus.focusControlsAreActive);
    const inviteFeature = useSelector((state: RootState) => state.features.inviteFeature);
    const session = useSelector((state: RootState) => state.session);
    const fileShareFeature = useSelector((state: RootState) => state.features.fileShareFeature);
    const uploadQueue = useSelector((state: RootState) => state.files.uploadQueue);
    const smartConnectFeature = useSelector((state: RootState) => state.features.smartConnectFeature);
    const smartConnectIsActive = useSelector((state: RootState) => state.application.smartConnectIsActive);
    const externalStreamIsActive = useSelector((state: RootState) => state.application.externalStreamIsActive);
    const sessionHandling = useSelector((state: RootState) => state.sessionHandlingSlice);
    const invitedUsers = useSelector((state: RootState) => state.invitedUsers);
    const isHeadMountedDisplayDevice = useSelector((state: RootState) => state.sessionHandlingSlice.isHeadMountedDisplayDevice);
    const callerPageLoaded = useSelector((state: RootState) => state.application.callerPageLoaded);

    const [mounted, setMounted] = useState(false);
    const systemPanelRef = useRef(null);

    const prevInvitedUsersRef = useRef<any[]>([]);
    const prevSessionHandlingRef = useRef(sessionHandling);
    const prevSessionRef = useRef(session);
    const prevVideoIsActiveRef = useRef(videoIsActive);
    const prevChatIsActiveRef = useRef(chatIsActive);
    const prevBidiIsActiveRef = useRef(bidiIsActive);
    const prevScreenshareIsActiveRef = useRef(screenshareIsActive);

    const onInit = async () => {
        if (!mounted) {
            if (videoIsActive) deactivateVideoDispatcherDispatch();
            if (chatIsActive) deactivateChatDispatcherDispatch();
            if (gpsIsActive) deactivateGPSDispatch();
            if (hdSendIsActive) deactivateHDSendDispatch();
            if (snapshotIsActive) deactivateSnapshotDispatch(true);
            if (snapshotFeature) disableSnapshotDispatch();
            if (pointerFeature) disablePointerDispatch();
            if (pointerIsActive) deactivatePointerDispatcherDispatch();
            if (drawIsActive) deactivateDrawDispatcherDispatch();
            if (drawFeature) disableDrawDispatch();
            setWhiteLabelLogo();
            setDispatcherLanguage();
            const videoBackgroundImage = await getImage({ type: 'base64', imageType: imageEndpointType.VIDEO_BACKGROUND });
            if (videoBackgroundImage) {
                dispatchAddVideoBackgroundImage({ image: videoBackgroundImage });
            }
            const dispatchCenterConfig = await getCurrentDispatchCenterConfig();

            if (dispatchCenterConfig && dispatchCenterConfig.timeToLive !== null) {
                dispatchAddDispatchCenterTtl(dispatchCenterConfig.timeToLive);
            } else {
                dispatchAddDispatchCenterTtl(DEFAULT_RECORDING_TTL);
            }

            setMounted(true);

            dispatchActivateFocusControls();
            store.dispatch(resetSessionHandlingData());
        }
    };

    const languageChange = languageLabel => {
        systemPanelRef.current.updateLanguage(languageLabel);
    };

    const _keys = {};

    const addKey = e => {
        if (e.keyCode === 116 || e.keyCode === 82 || e.keyCode === 17 || e.keyCode === 91) {
            _keys[e.keyCode] = true;

            // F5
            // CTRL + R
            // COMMAND + R
            if (_keys[116] || (_keys[82] && _keys[17]) || (_keys[82] && _keys[91])) {
                e.preventDefault();
                e.stopPropagation();
                Object.keys(_keys).forEach(key => {
                    _keys[key] = false;
                });
                alert(replaceText(texts, 'system.alertRefresh'));
            }
        }
    };

    const removeKey = e => {
        if (e.keyCode === 116 || e.keyCode === 82 || e.keyCode === 17 || e.keyCode === 91) {
            _keys[e.keyCode] = false;
            if (DEBUG) addLogDispatch(['keys', _keys]);
        }
    };

    const setFocusOnNextFeatureInLine = useCallback(() => {
        switch (true) {
            case videoIsActive:
                dispatchSetFocusWindowLiveVideo();
                sendSetFeatureFocus(FOCUS_FEATURE_TYPE.LIVE_VIDEO);
                break;
            case chatIsActive:
                dispatchSetFocusWindowChat(WebRtcManagerType.DISPATCHER);
                sendSetFeatureFocus(FOCUS_FEATURE_TYPE.CHAT);
                break;
            case bidiIsActive:
                dispatchSetFocusWindowBidi();
                sendSetFeatureFocus(FOCUS_FEATURE_TYPE.BIDI);
                break;
            case screenshareIsActive:
                dispatchSetFocusWindowScreenshare();
                sendSetFeatureFocus(FOCUS_FEATURE_TYPE.SCREEN_SHARE);
                break;
            case externalStreamIsActive:
                dispatchSetFocusWindowExternalStream();
                sendSetFeatureFocus(FOCUS_FEATURE_TYPE.EXTERNAL_STREAM);
                break;
            default:
                dispatchResetFocusWindow(WebRtcManagerType.DISPATCHER);
                sendSetFeatureFocus(FOCUS_FEATURE_TYPE.RESET_FOCUS);
                break;
        }
    }, [videoIsActive, chatIsActive, bidiIsActive, screenshareIsActive, externalStreamIsActive]);

    useEffect(() => {
        window.addEventListener('keydown', addKey);
        window.addEventListener('keyup', removeKey);
        onInit();

        return () => {
            window.removeEventListener('keydown', addKey);
            window.removeEventListener('keyup', removeKey);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        prevInvitedUsersRef.current = invitedUsers;
    }, [invitedUsers]);

    useEffect(() => {
        prevSessionHandlingRef.current = sessionHandling;
    }, [sessionHandling]);

    useEffect(() => {
        prevSessionRef.current = session;
    }, [session]);

    useEffect(() => {
        prevVideoIsActiveRef.current = videoIsActive;
    }, [videoIsActive]);

    useEffect(() => {
        prevChatIsActiveRef.current = chatIsActive;
    }, [chatIsActive]);

    useEffect(() => {
        prevBidiIsActiveRef.current = bidiIsActive;
    }, [bidiIsActive]);

    useEffect(() => {
        prevScreenshareIsActiveRef.current = screenshareIsActive;
    }, [screenshareIsActive]);

    // on invitedUsers state update, send session handling data to conference users
    useEffect(() => {
        const prevInvitedUsers = prevInvitedUsersRef.current;

        if (prevInvitedUsers && prevInvitedUsers.length !== invitedUsers.length) {
            sendSessionHandlingData();
        }
    }, [invitedUsers]);

    // on activeDeviceId update, send session handling data to conference users
    useEffect(() => {
        const prevSessionHandling = prevSessionHandlingRef.current;
        if (sessionHandling.activeDeviceId !== prevSessionHandling.activeDeviceId) {
            sendSessionHandlingData();
        }
    }, [sessionHandling.activeDeviceId]);

    // on session devices update, send session handling data to conference users
    useEffect(() => {
        const prevSessionHandling = prevSessionHandlingRef.current;
        if (sessionHandling.devices.length !== prevSessionHandling.devices.length) {
            sendSessionHandlingData();
        }
    }, [sessionHandling.devices.length]);

    // on session update, send session handling data to conference users under conditions
    useEffect(() => {
        const prevSession = prevSessionRef.current;
        if (session !== prevSession) {
            if (
                session.accuracy !== prevSession.accuracy ||
                session.browser !== prevSession.browser ||
                session.chatHistory.length !== prevSession.chatHistory.length ||
                session.language !== prevSession.language ||
                session.lat !== prevSession.lat ||
                session.long !== prevSession.long ||
                session.osName !== prevSession.osName ||
                session.osVersion !== prevSession.osVersion
            ) {
                sendSessionData();
                sendSessionHandlingData();
            }
        }
    }, [session]);

    // videoIsActive deactivation, reset focus to next in line
    useEffect(() => {
        if (videoIsActive !== prevVideoIsActiveRef.current && !videoIsActive && currentFocusFeature === FOCUS_FEATURE_TYPE.LIVE_VIDEO) {
            setFocusOnNextFeatureInLine();
        }
    }, [videoIsActive, currentFocusFeature, setFocusOnNextFeatureInLine]);

    // chatIsActive deactivation, reset focus to next in line
    useEffect(() => {
        if (chatIsActive !== prevChatIsActiveRef.current && !chatIsActive && currentFocusFeature === FOCUS_FEATURE_TYPE.CHAT) {
            setFocusOnNextFeatureInLine();
        }
    }, [chatIsActive, currentFocusFeature, setFocusOnNextFeatureInLine]);

    // bidiIsActive deactivation, reset focus to next in line
    useEffect(() => {
        if (bidiIsActive !== prevBidiIsActiveRef.current && !bidiIsActive && currentFocusFeature === FOCUS_FEATURE_TYPE.BIDI) {
            setFocusOnNextFeatureInLine();
        }
    }, [bidiIsActive, currentFocusFeature, setFocusOnNextFeatureInLine]);

    // screenshareIsActive deactivation, reset focus to next in line
    useEffect(() => {
        if (screenshareIsActive !== prevScreenshareIsActiveRef.current && !screenshareIsActive && currentFocusFeature === FOCUS_FEATURE_TYPE.SCREEN_SHARE) {
            setFocusOnNextFeatureInLine();
        }
    }, [screenshareIsActive, currentFocusFeature, setFocusOnNextFeatureInLine]);

    // send current focus feature to conference users
    useEffect(() => {
        if (currentFocusFeature) {
            const message = {
                data: WebRtcMessageDispatcher.CURRENT_FOCUS_FEATURE,
                currentFocusFeature: currentFocusFeature,
            };
            dispatcherWebRTCManager.sendMessageToAllConferenceUsers(message);
        }
    }, [currentFocusFeature]);

    // send audio activation status to conference users
    useEffect(() => {
        if (audioStreamIsActive) {
            sendAudioActivationStatusFromStore();
        }
    }, [audioStreamIsActive]);

    const classes = clsx('r', {
        'r--chat': chatFeature,
    });

    return (
        <>
            <div className="dispatcherDashboard">
                <DispatcherNavbar />
                <div className="grid">
                    <div className={classes}>
                        <div className="c">
                            <GridBox isContainer={true} addClass="rtcSwitchBox" headline={replaceText(texts, 'info.function')}>
                                {/* TODO: remove apizee refs */}
                                {/* <WebRTCSwitcher /> */}
                            </GridBox>
                            <GridBox
                                isClosed={true}
                                isContainer={true}
                                openOnFeatureActivation={videoIsActive}
                                addClass="cameraChooser"
                                headline={replaceText(texts, 'cameradropdown.title')}>
                                {/* TODO: remove apizee refs */}
                                {/* <CameraChooser /> */}
                            </GridBox>
                            {smartConnectFeature && (
                                <GridBox
                                    isClosed={true}
                                    isContainer={true}
                                    openOnFeatureActivation={smartConnectIsActive}
                                    addClass="smartConnectBox"
                                    headline={replaceText(texts, 'smartConnectDropdown.title')}>
                                    {/* TODO: remove apizee refs */}
                                    {/* <SmartConnect /> */}
                                </GridBox>
                            )}
                            {(inviteFeature || audioStreamFeature) && (
                                <GridBox
                                    addClass="infoBox"
                                    isClosed={true}
                                    openOnFeatureActivation={audioStreamIsActive}
                                    isContainer={true}
                                    headline={replaceText(texts, 'invitation.title')}>
                                    {/* TODO: remove apizee refs */}
                                    {/* <InvitationPanel /> */}
                                </GridBox>
                            )}
                            <GridBox addClass="infoBox" isContainer={true} headline={replaceText(texts, 'info.system')}>
                                {/* TODO: remove apizee refs */}
                                {/* <SystemPanel /> */}
                            </GridBox>
                            <GridBox
                                addClass="infoBox"
                                isClosed={true}
                                openOnFeatureActivation={gpsIsActive}
                                isContainer={true}
                                headline={replaceText(texts, 'info.position')}>
                                {/* TODO: remove apizee refs */}
                                {/* <GeoPanel /> */}
                            </GridBox>
                        </div>
                        <div className="c">
                            <GridBox
                                isContainer={true}
                                isClosed={true}
                                openOnFeatureActivation={gpsIsActive}
                                addClass="mapBox"
                                headline={replaceText(texts, 'info.map')}>
                                {/* TODO: remove apizee refs */}
                                {/* <OpenStreetmap /> */}
                            </GridBox>
                            <GridBox
                                isContainer={true}
                                alwaysOpened={drawIsActive || (videoIsActive && !focusControlsAreActive)}
                                addClass="videoBox"
                                isFocusable={videoIsActive || externalStreamIsActive}
                                isInFocus={currentFocusFeature === FOCUS_FEATURE_TYPE.LIVE_VIDEO || currentFocusFeature === FOCUS_FEATURE_TYPE.EXTERNAL_STREAM}
                                changeFocus={() => {
                                    if (videoIsActive) {
                                        dispatchSetFocusWindowLiveVideo();
                                        sendSetFeatureFocus(FOCUS_FEATURE_TYPE.LIVE_VIDEO);
                                    }
                                    if (externalStreamIsActive) {
                                        dispatchSetFocusWindowExternalStream();
                                        sendSetFeatureFocus(FOCUS_FEATURE_TYPE.EXTERNAL_STREAM);
                                    }
                                }}
                                headline={replaceText(texts, 'info.video')}>
                                {/* TODO: remove apizee refs */}
                                {/* <VideoContainerDispatcher /> */}
                                {/* <ExternalStreamPanel isDispatcher={true} /> */}
                            </GridBox>
                            {(snapshotFeature || hdSendFeature || streamRecordingFeature || fileShareFeature) && (
                                <GridBox
                                    isClosed={true}
                                    isContainer={true}
                                    openOnFeatureActivation={snapshotIsActive || drawIsActive || streamRecordingIsActive || uploadQueue.length !== 0}
                                    addClass="mediaBox"
                                    headline={replaceText(texts, 'media.boxHeader')}>
                                    {/* TODO: remove apizee refs */}
                                    {/* <MediaGallery /> */}
                                </GridBox>
                            )}
                        </div>
                        <div className="c">
                            {bidiFeature && (
                                <GridBox
                                    isContainer={true}
                                    alwaysOpened={bidiIsActive && !focusControlsAreActive}
                                    addClass="bidiBox"
                                    isClosed={true}
                                    openOnFeatureActivation={bidiIsActive}
                                    isFocusable={bidiIsActive && !drawIsActive}
                                    isInFocus={currentFocusFeature === FOCUS_FEATURE_TYPE.BIDI}
                                    changeFocus={() => {
                                        dispatchSetFocusWindowBidi();
                                        sendSetFeatureFocus(FOCUS_FEATURE_TYPE.BIDI);
                                    }}
                                    headline={replaceText(texts, 'info.bidi')}>
                                    {/* TODO: remove apizee refs */}
                                    {/* <BidiPanel /> */}
                                </GridBox>
                            )}
                            {notesFeature && (
                                <GridBox isContainer={true} addClass="noteBox" isClosed={true} headline={replaceText(texts, 'info.notes')}>
                                    {/* TODO: remove apizee refs */}
                                    {/* <NotesPanel /> */}
                                </GridBox>
                            )}
                            {screenShareFeature && (
                                <GridBox
                                    isContainer={true}
                                    alwaysOpened={screenshareIsActive && !focusControlsAreActive}
                                    addClass="screenshareBox"
                                    isClosed={true}
                                    openOnFeatureActivation={screenshareIsActive}
                                    isFocusable={screenshareIsActive && !drawIsActive}
                                    isInFocus={currentFocusFeature === FOCUS_FEATURE_TYPE.SCREEN_SHARE}
                                    changeFocus={() => {
                                        dispatchSetFocusWindowScreenshare();
                                        sendSetFeatureFocus(FOCUS_FEATURE_TYPE.SCREEN_SHARE);
                                    }}
                                    headline={replaceText(texts, 'info.screenshare')}>
                                    {/* TODO: remove apizee refs */}
                                    {/* <ScreensharePanel /> */}
                                </GridBox>
                            )}
                            {chatFeature && (
                                <GridBox
                                    isContainer={true}
                                    alwaysOpened={chatIsActive && !focusControlsAreActive}
                                    addClass="chatBox"
                                    isFocusable={!(drawIsActive || isHeadMountedDisplayDevice) && callerPageLoaded}
                                    isInFocus={currentFocusFeature === FOCUS_FEATURE_TYPE.CHAT}
                                    changeFocus={() => {
                                        dispatchSetFocusWindowChat(WebRtcManagerType.DISPATCHER);
                                        sendSetFeatureFocus(FOCUS_FEATURE_TYPE.CHAT);
                                    }}
                                    headline={replaceText(texts, 'info.chat')}>
                                    {/* TODO: remove apizee refs */}
                                    <ChatViewDispatcher handleLanguageChange={languageChange} />
                                </GridBox>
                            )}
                        </div>
                    </div>
                </div>
                <SystemFooter />
                {smsGuideFeature && <SMSGuide />}
                <TimeoutOverlay />
                <div
                    id="incomingStreamToDispatcher"
                    style={{
                        display: 'none',
                        position: 'absolute',
                    }}></div>
                {/* Hidden dummy stream canvas */}
                <video
                    id="hiddenCanvas"
                    style={{
                        display: 'none',
                        position: 'absolute',
                    }}></video>
            </div>
        </>
    );
};

export default DispatcherDashboard;
