import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers/index';
import webRtcListenerMiddleware from '../middleware/webRtcListenerMiddleware';

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(webRtcListenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
