export const endpointKeys = [
    'REACT_APP_RTC_PRIMARY_APIKEY_ENDPOINT',
    'REACT_APP_RTC_SECONDARY_APIKEY_ENDPOINT',
    'REACT_APP_LOGIN_URL_ENDPOINT',
    'REACT_APP_CREATE_BYSTANDER_URL',
    'REACT_APP_SEND_SMS_URL',
    'REACT_APP_TRANSLATE_URL',
    'REACT_APP_DELETE_BYSTANDER_URL',
    'REACT_APP_WELCOMEMESSAGE_URL',
    'REACT_APP_SYSTEMHEALTH_URL',
    'REACT_APP_SESSIONEND_URL',
    'REACT_APP_POLLING_API',
    'REACT_APP_POLLING_CONSUMED',
    'REACT_APP_LOG_API',
    'REACT_APP_CHAT_POLLING_API',
    'REACT_APP_VERSION_POLLING_API',
    'REACT_APP_REFRESH_TOKEN_URL',
    'REACT_APP_CHATBLOCKS_URL',
    'REACT_APP_SHORT_TOKEN_CONSUMED',
    'REACT_APP_NEWLOCATION_URL',
    'REACT_APP_SEND_GEO_SMS_URL',
    'REACT_APP_APPLICATION_DISPATCHER',
    'REACT_APP_APPLICATION_CALLER',
    'REACT_APP_LOGOUT_TOKEN_ENDPOINT',
    'REACT_APP_SESSION_LOG_PDF',
    'REACT_APP_LANGUAGE_GET',
    'REACT_APP_LANGUAGE_SET',
    'REACT_APP_IMAGE_DISPLAY_ENDPOINT',
    'REACT_APP_CONTACTS_ENDPOINT',
    'REACT_APP_MIMETYPE_ENDPOINT',
    'REACT_APP_IMAGE_CONVERT_ENDPOINT',
    'REACT_APP_IMAGE_OVERLAY_ENDPOINT',
    'REACT_APP_EMAIL_ENDPOINT',
    'REACT_APP_PUBLISH_CHAT_MESSAGE_ENDPOINT',
    'REACT_APP_UPLOAD_FILE_ENDPOINT',
    'REACT_APP_CONFERENCE_LOGO_ENDPOINT',
    'REACT_APP_SESSIONENDCONFERENCE_URL',
    'REACT_APP_INVITE_EMAIL_TEXT',
    'REACT_APP_INVITE',
    'REACT_APP_DISPATCHCENTER_CONFIGURATION_GET',
    'REACT_APP_NEW_RECORDING_ENDPOINT',
    'REACT_APP_DISPATCHER_WEBRTC_ALIAS',
    'REACT_APP_BACKGROUND_IMAGE_GET',
    'REACT_APP_NEW_FILE_TRANSFER',
    'REACT_APP_SEND_SESSION_DURATION',
    'REACT_APP_SEND_INVITATION_SMS',
    'REACT_APP_EXTERNAL_STREAM_ENDPOINT',
    'REACT_APP_RESOLVE_TOKEN_URL',
    'REACT_APP_DISCLAIMER_DISPATCHER_ENDPOINT',
    'REACT_APP_DISCLAIMER_CALLER_ENDPOINT',
    'REACT_APP_DISCLAIMER_DISPATCHER_ACCEPT_ENDPOINT',
    'REACT_APP_PASSWORD_EXPIRATION_GET',
    'REACT_APP_SEND_PASSWORD_CHANGE_REQUEST',
];
