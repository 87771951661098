import { createBystander, sendSMSAPI } from "../api/backendApi";
import { normalizePhone } from "../helper/helper";
import { setPhone } from "../redux/slices/authSessionSlice";
import { setWebRtcToken } from "../redux/slices/webRtc/authSlice";
import { setChatThreadId } from "../redux/slices/webRtc/chatThreadSlice";
import { setRoomId } from "../redux/slices/webRtc/roomSlice";
import store from "../redux/store";

export const dispatcherAuthManager = {
    isPhoneNumberConsumed: false,
    phone: null,
    sessionId: null,

    /**
     * create a bystander user
     * @param {string} phoneNumber
     */
    createBystanderAndWebRtcProperties: async phoneNumber => {
        const result = await createBystander(phoneNumber);
        if (result) {
            dispatcherAuthManager.sessionId = result.token;
            store.dispatch(setWebRtcToken(result.webRtcProperties.webRtcToken));
            store.dispatch(setRoomId(result.webRtcProperties.roomId));
            store.dispatch(setChatThreadId(result.webRtcProperties.chatThreadId));
        }
        return dispatcherAuthManager.sessionId;
    },

    /**
     * send a sms
     * @param {boolean} dryRun
     */
    sendSMS: async (dryRun = false, isResendSMS = false) => {
        const normalizedPhone = normalizePhone(dispatcherAuthManager.phone);
        store.dispatch(setPhone(normalizedPhone));

        const data = {
            token: dispatcherAuthManager.sessionId,
            bystanderId: store.getState().authSessionSlice.dispatcherSessionId,
            phoneNumber: normalizedPhone,
            dryRun: dryRun,
            isResendSMS: isResendSMS,
        };

        return await sendSMSAPI(data);
    },

    /**
     * init a new session
     * @param {string} phone
     */
    initSession: phone => {
        dispatcherAuthManager.phone = phone;
    },
};
