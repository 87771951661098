// webRtcReducer.js
import { combineReducers } from 'redux';
import authSlice from '../slices/webRtc/authSlice';
import chatThreadSlice from '../slices/webRtc/chatThreadSlice';
import roomSlice from '../slices/webRtc/roomSlice';

const webRtc = combineReducers({
    authSlice,
    chatThreadSlice,
    roomSlice,
});

export default webRtc;
