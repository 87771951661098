import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { CallClient } from '@azure/communication-calling';
import { ChatClient } from '@azure/communication-chat';
import store from '../../redux/store';
import { addComplexObject } from '../../helper/complexObjectStorage';


// TODO: on completion, refactor
export const initializeTokenCredential = async () => {
    const credential = new AzureCommunicationTokenCredential(store.getState().webRtc.authSlice.token);
    if (credential) {
        addComplexObject('tokenCredential', credential);
        joinChatThread(credential);
        joinRoom(credential);
    }
}

const joinRoom = async (credential) => {
    const callClient = new CallClient();
    const callAgent = await callClient.createCallAgent(credential);
    const call = callAgent.join({
        roomId: store.getState().webRtc.roomSlice.roomId,
    });

    if (callClient) {
        console.log('callClient: ', callClient);
        addComplexObject('callClient', callAgent);
    }

    if (callAgent) {
        console.log('callAgent: ', callAgent);
    }

    if (call) {
        console.log('call: ', call);
    }
}

export const handleChatMessage = (e) => {
    const eexMessage = JSON.parse(e.message);

    if (eexMessage.type === 'system') {
        console.log('system message: ', e);
    }
}

const joinChatThread = async (credential) => {
    const chatClient = new ChatClient(process.env.REACT_APP_ACS_ENDPOINT, credential);
    const chatThreadClient = chatClient.getChatThreadClient(store.getState().webRtc.chatThreadSlice.chatThreadId);

    if (chatClient) {
        console.log('chatClient: ', chatClient);
        addComplexObject('chatClient', chatClient);

        await chatClient.startRealtimeNotifications();
        chatClient.on('chatMessageReceived', async e => {
            handleChatMessage(e);
        });
    }

    if (chatThreadClient) {
        console.log('chatThreadClient: ', chatThreadClient);
        addComplexObject('chatThreadClient', chatThreadClient);
    }
}