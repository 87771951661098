import { changeCSSVariablesTo } from './helper';
import { systemConfig } from "./systemConfigBuilder";

let config = {}; // Placeholder for the JSON configuration
export const cssVariables = {}; // Object to hold all theme variables

const loadThemeConfig = async () => {
    try {
        const response = await fetch(`/config/themes/${systemConfig.REACT_APP_THEME}/theme.json`);
        if (!response.ok) {
            throw new Error('Failed to load theme configuration');
        }
        config = await response.json();

        // Populate cssVariables dynamically and create exports
        Object.assign(cssVariables, config);
    } catch (error) {
        console.error('Error loading theme configuration:', error);
    }
};

export const loadTheme = async () => {
    await loadThemeConfig();
    changeCSSVariablesTo();
}

export const logo = `/config/themes/${systemConfig.REACT_APP_THEME}/logo.svg`;
export const footerLogo = `/config/themes/${systemConfig.REACT_APP_THEME}/emeye-t-inside-logo.svg`;
export const companyTitle = 'Company';
