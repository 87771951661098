import { apiEndpoints } from "../helper/endpointBuilder";
import { errorLog } from "../helper/logging";
import { oauthStore } from "../store/OAuthStore";
import { systemConfig } from "../helper/systemConfigBuilder";
import { getData, postData } from "./common";

export const getPasswordExpiration = async () => {
    const result = await getData(apiEndpoints.REACT_APP_PASSWORD_EXPIRATION_GET, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getPasswordExpiration() error getting data from backend. - code: GT PWEXP',
            error: e,
            eventId: 'PASSWORD_EXPIRATION',
        });
    });

    return result;
}

export const sendChangePasswordRequest = async () => {
    const clientId = systemConfig.REACT_APP_APP_CLIENT_ID;
    const result = await postData(apiEndpoints.REACT_APP_SEND_PASSWORD_CHANGE_REQUEST, { clientId }, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'postSendChange() error posting to backend. - code: PT PWCHG',
            error: e,
            eventId: 'PASSWORD_SEND_CHANGE',
        });
    });

    return result;
};