import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RoomState {
    roomId: string | null;
    isRoomConnected: boolean;
}

const initialState: RoomState = {
    roomId: null,
    isRoomConnected: false,
};

const RoomSlice = createSlice({
    name: 'webRtc/roomSlice',
    initialState,
    reducers: {
        setRoomId(state, action: PayloadAction<any>) {
            state.roomId = action.payload;
        },
        setRoomIsConnected(state, action: PayloadAction<any>) {
            state.isRoomConnected = action.payload;
        },
        resetRoomSlice() {
            return initialState;
        },
    },
});

export const { setRoomId, setRoomIsConnected, resetRoomSlice } = RoomSlice.actions;

export default RoomSlice.reducer;
