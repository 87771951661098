import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    token: string | null;
}

const initialState: AuthState = {
    token: null,
};

const authSlice = createSlice({
    name: 'webRtc/authSlice',
    initialState,
    reducers: {
        setWebRtcToken(state, action: PayloadAction<any>) {
            state.token = action.payload;
        },
        resetAuthSlice() {
            return initialState;
        },
    },
});

export const { setWebRtcToken, resetAuthSlice } = authSlice.actions;

export default authSlice.reducer;
