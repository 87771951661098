import { postData } from '../api/common';
import { oauthStore } from '../store/OAuthStore';
import { addNotificationAndShowDispatch } from '../redux/actions/notifications';
import { DEBUG, DISPLAY_ONLY_IN_SESSION, TRANSLATION_TIME_WARNING } from '../config';
import { addLogDispatch } from '../redux/actions/logs';
import { dispatcherWebRTCManager } from '../webrtc/webrtcManagers/DispatcherWebRTCManager';
import { errorLog } from './logging';
import { apiEndpoints } from './endpointBuilder';

/**
 * translate a text from a language to another
 * @param {string} text
 * @param {string} to
 * @param {string} from
 * @returns {string} translated text
 */
export const translateTextTo = async (text, to, from) => {
    const preparedText = [text];

    const response = await translateArrayTo(preparedText, to, from);

    return response[0];
};

/**
 * translate an array of texts from a language to another
 * @param {array} translationArray
 * @param {string} to
 * @param {string} from
 * @returns {array} translated array
 */
export const translateArrayTo = async (translationArray, to, from) => {
    if (to === from) return translationArray;
    const data = {
        texts: [...translationArray],
        from: from,
        to: to,
    };
    const url = apiEndpoints.REACT_APP_TRANSLATE_URL;
    const results = await postData(url, data, oauthStore.authToken).catch(e => {
        addNotificationAndShowDispatch('error.trnslt', 'error', DISPLAY_ONLY_IN_SESSION);
        errorLog({
            message: 'Error while translating. - code: TRNSLT',
            error: e,
            eventId: 'TRANSLATION',
        });
    });
    // if result retrun result
    dispatcherWebRTCManager.translationError = false;
    if (results && results.texts) return results.texts;

    // else return original texts
    dispatcherWebRTCManager.translationError = true;
    return [...translationArray];
};

export const translateAllChatTexts = async ({ chatMessages }, to, from) => {
    const timer = new Timer();

    const preparedMessages = [];

    if (chatMessages && chatMessages.length > 0) {
        timer.startTimer();
        chatMessages.forEach(message => {
            preparedMessages.push(message.chatMessage);
        });

        const translatedMessages = preparedMessages.length > 0 ? await translateArrayTo(preparedMessages, to, from) : [];

        if (DEBUG) addLogDispatch(['translated chat messages', translatedMessages]);

        timer.stopTimer();

        return {
            chatMessages: chatMessages.map((message, i) => {
                return {
                    ...message,
                    chatMessage: translatedMessages[i],
                };
            }),
        };
    }
};

/**
 * translate a message and keep track of the duration
 * @param {string} message
 * @param {string} to
 * @param {string} from
 * @returns {object} message and translation
 */
export const translateMessage = async (message, to, from) => {
    const timer = new Timer();
    timer.startTimer();

    const translated = await translateArrayTo([message.chatMessage], to, from);

    timer.stopTimer();

    return {
        ...message,
        chatMessage: translated[0],
    };
};

/**
 * Timer class
 */
class Timer {
    timeBefore = null;
    timeout = null;
    long = false;
    errorId = null;

    /**
     * start the timer
     */
    startTimer = () => {
        this.timeBefore = new Date();
        this.long = false;
        this.timeOut = setTimeout(() => {
            this.long = true;
            this.errorId = addNotificationAndShowDispatch('info.trnslt_lngr', 'info', DISPLAY_ONLY_IN_SESSION);
        }, TRANSLATION_TIME_WARNING);
    };
    /**
     * stop the timer and check the duration
     */
    stopTimer = () => {
        clearTimeout(this.timeOut);
        const timeAfter = new Date();
        const timing = timeAfter - this.timeBefore;

        if (DEBUG) addLogDispatch(['Translation Timing', timing]);
    };
}
