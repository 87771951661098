import { systemConfigKeys } from './systemConfigKeys';

// Uses dynamic values stored in systemConfig. Falls back to .env value if systemConfig value is empty
const setConfigEntry = key => {
    const configValue = window.globalSystemConfig && window.globalSystemConfig[key];
    if (configValue) {
        switch (typeof configValue) {
            case 'string':
                if (configValue.length > 0) {
                    return configValue;
                }
                break;
            case 'boolean':
                if (configValue === true) {
                    return configValue;
                }
                break;
            default:
                break;
        }
    }

    // If no valid configValue is returned, fallback to process.env[key]
    return process.env[key];

};

const systemConfig = Object.fromEntries(systemConfigKeys.map(key => [key, setConfigEntry(key)]));

export { systemConfig };
