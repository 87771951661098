import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChatThreadState {
    chatThreadId: string | null;
    isChatThreadConnected: boolean;
}

const initialState: ChatThreadState = {
    chatThreadId: null,
    isChatThreadConnected: false,
};

const ChatThreadSlice = createSlice({
    name: 'webRtc/chatThreadSlice',
    initialState,
    reducers: {
        setChatThreadId(state, action: PayloadAction<any>) {
            state.chatThreadId = action.payload;
        },
        setChatThreadIsConnected(state, action: PayloadAction<any>) {
            state.isChatThreadConnected = action.payload;
        },
        resetChatThreadSlice() {
            return initialState;
        },
    },
});

export const { setChatThreadId, setChatThreadIsConnected, resetChatThreadSlice } = ChatThreadSlice.actions;

export default ChatThreadSlice.reducer;
