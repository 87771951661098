import { oauthStore } from '../store/OAuthStore';
import { getData, postData, deleteData, forceLogout } from './common';
import { DEBUG, DISPLAY_ONLY_IN_SESSION, IMAGE_FORMAT, IMAGE_TARGET } from '../config';
import { addNotificationAndShowDispatch } from '../redux/actions/notifications';
import { callerWebRTCManager } from '../webrtc/webrtcManagers/CallerWebRTCManager';
import { dispatcherWebRTCManager } from '../webrtc/webrtcManagers/DispatcherWebRTCManager';
import { timeLog, errorLog } from '../helper/logging';
import { addLogDispatch } from '../redux/actions/logs';
import { disableSessionDownloadDispatch, resetSessionDispatch, startSessionDispatch } from '../redux/actions/session';
import { convertBase64ToBlob, convertBlobToBase64, createKpiLog, normalizePhone, obscurePhonenumber } from '../helper/helper';
import { getURLParams } from '../helper/rtcFlowHandling';
import { invitedUserWebRTCManager } from '../webrtc/webrtcManagers/InvitedUserWebRTCManager';
import { deactivateExternalStreamDispatch } from '../redux/actions/application';
import { dispatcherAuthManager } from '../store/DispatcherAuthManager';
import { imageEndpointType, WebRtcManagerType } from '../types';
import { apiEndpoints } from '../helper/endpointBuilder';

let isCaller = false;

/**
 * backendApi
 * all connections to the backend
 */

/**
 * gets the system health from the backend
 * @returns {object} system health
 */
export const getSystemHealth = async () => {
    const results = await getData(apiEndpoints.REACT_APP_SYSTEMHEALTH_URL, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getSystemHealth() error getting data from backend. - code: GT HLTH',
            error: e,
            eventId: 'SYSTEM_HEALTH',
        });
    });
    if (results) {
        if (DEBUG) addLogDispatch(['getSystemHealth', results]);
        return results;
    }
};

/**
 * gets the version from the backend
 * @returns {string} version
 */
export const getVersion = async () => {
    const results = await getData(apiEndpoints.REACT_APP_VERSION_POLLING_API, oauthStore.authToken, 'text').catch(e => {
        errorLog({
            message: 'getVersion() error getting data from backend. - code: GT VRSN',
            error: e,
            eventId: 'VERSION',
        });
    });
    if (results) {
        if (DEBUG) addLogDispatch(['getVersion', results]);
        return results;
    }
};

/**
 * gets the welcome messages from the backend
 * @returns {array} welcome messages
 */
export const getWelcomeMessages = async () => {
    const results = await getData(apiEndpoints.REACT_APP_WELCOMEMESSAGE_URL, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getWelcomeMessages() error getting data from backend. - code: GT WLCM',
            error: e,
            eventId: 'WELCOME_MESSAGES',
        });
    });
    if (results) {
        if (DEBUG) addLogDispatch(['getWelcomeMessages', results]);
        return results;
    }
};

/**
 * gets the login endpoint from the backend
 * @returns {string} url login endpoint
 */
export const getLoginEndpoint = async () => {
    const endpoint = await getData(apiEndpoints.REACT_APP_LOGIN_URL_ENDPOINT)
        .then(response => {
            return response.url;
        })
        .catch(e => {
            errorLog({
                message: 'getLoginEndpoint() error getting data from backend - code: LGN USR',
                error: e,
                eventId: 'LOGIN_USER',
            });
            addNotificationAndShowDispatch('error.lgn_usr', 'error');
        });

    if (endpoint) {
        if (DEBUG) addLogDispatch(['endpointURL', endpoint]);
        return endpoint;
    }
};

/**
 * creates a bystander user in the backend for a given phonenumber
 * @param {string} phoneNumber
 */
export const createBystander = async phoneNumber => {
    const formattedPhone = normalizePhone(phoneNumber);
    return await postData(apiEndpoints.REACT_APP_CREATE_BYSTANDER_URL, { phoneNumber: formattedPhone }, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'createBystander() error getting data from authserver. - code: CRT BYST',
            error: e,
            eventId: 'CREATE_BYSTANDER',
        });
        addNotificationAndShowDispatch('error.crt_bystndr', 'error');
    });
};

/**
 * sends a sms via the backend to the given number
 * @param {object} data object with phonenumber
 * @returns {object} sms response data
 */
export const sendSMSAPI = async data => {
    if (data.isResendSMS) {
        const additionalStates = {
            0: obscurePhonenumber(dispatcherAuthManager.phone),
        };

        createKpiLog('infoSmsResent', '', additionalStates);
    } else {
        const additionalStates = {
            0: obscurePhonenumber(dispatcherAuthManager.phone),
        };

        resetSessionDispatch();

        startSessionDispatch({
            userId: dispatcherWebRTCManager.webRtcSessionId,
            sessionId: dispatcherAuthManager.sessionId,
            phone: dispatcherAuthManager.phone,
            dispatchCenter: oauthStore.organizationName,
            user: oauthStore.userName,
        });

        createKpiLog('infoSmsSent', '', additionalStates);

        timeLog('session');
    }

    return await postData(apiEndpoints.REACT_APP_SEND_SMS_URL, data, oauthStore.authToken).catch(e => {
        // addNotificationAndShowDispatch('error.snd_sms', 'error');
        if (data.isResendSMS) {
            errorLog({
                message: 'sendSMSAPI() error posting data to backend - code: RESEND SMS',
                error: e,
                eventId: 'RESEND_SMS',
            });
        } else {
            errorLog({
                message: 'sendSMSAPI() error posting data to backend - code: SND SMS',
                error: e,
                eventId: 'SEND_SMS',
            });
            throw new Error('smsError');
        }
    });
};

/**
 * delete a bystander user from the backend
 * @param {string} phoneNumber phonenumber of the user
 * @returns {object} response object
 */
export const deleteBystander = async phoneNumber => {
    const formattedPhone = normalizePhone(phoneNumber);
    return postData(apiEndpoints.REACT_APP_DELETE_BYSTANDER_URL, { phoneNumber: formattedPhone }, oauthStore.authToken, false).catch(e => {
        errorLog({
            message: 'deleteBystander error posting data to backend - code: DLT BYST',
            error: e,
            eventId: 'DELETE_BYSTANDER',
        });
        addNotificationAndShowDispatch('error.dlt_bystndr', 'error');
    });
};

/**
 * get phonenumber from backend instead of input
 * @returns {object} response object
 */
export const getPhoneNumber = async () => {
    return getData(apiEndpoints.REACT_APP_POLLING_API, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getPhoneNumber() error getting data from backend - code: GT PHNNMBR',
            error: e,
            eventId: 'GET_PHONENUMBER',
        });
        // addNotificationAndShowDispatch('error.gt_phn', 'error');
        throw Error('POLLING ERROR');
    });
};

/**
 * consume a given phonenumber to stop the polling
 * @param {string} phoneNumber given phonenumber
 * @returns {object} response object
 */
export const phoneNumberConsumed = async phoneNumber => {
    dispatcherAuthManager.isPhoneNumberConsumed = true;
    dispatcherAuthManager.initSession(phoneNumber);

    return postData(apiEndpoints.REACT_APP_POLLING_CONSUMED + encodeURIComponent(phoneNumber), {}, oauthStore.authToken, false).catch(e => {
        errorLog({
            message: 'phoneNumberConsumed() error getting data from backend - code: PST PHNNMBR',
            error: e,
            eventId: 'POST_PHONENUMBER',
        });
    });
};

/**
 * post a chat message from backend instead of user input
 * @param {string} phoneNumber given phonenumber
 * @returns {object} chat message
 */
export const getChatMessage = async phoneNumber => {
    const formattedPhone = normalizePhone(phoneNumber);
    return postData(apiEndpoints.REACT_APP_CHAT_POLLING_API, { phoneNumber: formattedPhone }, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getChatMessage() error getting data from backend - code: GT CHTMSG',
            error: e,
            eventId: 'GT CHTMSG',
        });
        throw Error('POLLING ERROR');
    });
};

/**
 * get a chat blocks from the backend
 * @returns {object} chat blocks
 */
export const getChatBlocks = async () => {
    const results = await getData(apiEndpoints.REACT_APP_CHATBLOCKS_URL, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getChatBlocks() error getting data from backend. - code: CHT BLKS',
            error: e,
            eventId: 'CHAT_BLOCKS',
        });
    });
    if (results) {
        if (DEBUG) addLogDispatch(['getChatBlocks', results]);
        return results;
    }
};

/**
 * consume a chat message for the given phonenumber
 * @param {string} phoneNumber given phonenumber
 * @returns {object} response object
 */
export const chatMessageConsumed = async phoneNumber => {
    const formattedPhone = normalizePhone(phoneNumber);
    return deleteData(apiEndpoints.REACT_APP_CHAT_POLLING_API, oauthStore.authToken, true, { phoneNumber: formattedPhone }).catch(e => {
        errorLog({
            message: 'chatMessageConsumed() error getting deleting data from backend - code: DLT CHTMSG',
            error: e,
            eventId: 'DLT CHTMSG',
        });
    });
};

/**
 * create a log event for the backend to pass to the logging system
 * @param {object} data log data
 * @returns {object} response data
 */
let postLogEventError = false;
export const postLogEvent = async data => {
    let token = oauthStore.authToken;
    const { sessionId } = getURLParams();
    if (!postLogEventError && !oauthStore.authToken) {
        isCaller = true;
        await getAuthtoken(sessionId);
        if (callerWebRTCManager.authToken) token = callerWebRTCManager.authToken;
    }

    return postData(apiEndpoints.REACT_APP_LOG_API, data, token, false)
        .then(response => {
            postLogEventError = false;
            return response;
        })
        .catch(e => {
            postLogEventError = true;
            errorLog({
                message: data.message,
                sessionId: sessionId,
                error: e,
                eventId: 'PST_LG_EVNT',
            });
            if (e.message.indexOf('NOT_FOUND') !== -1) return;
        });
};

/**
 * create a error log event for the backend to pass to the logging system
 * @param {object} data log data
 * @returns {object} response data
 */
export const postLogError = async data => {
    let token = oauthStore.authToken;
    if (!oauthStore.authToken) {
        if (callerWebRTCManager.authToken) token = callerWebRTCManager.authToken;
    }
    return postData(apiEndpoints.REACT_APP_LOG_API, data, token ? token : null, false)
        .then(response => {
            return response;
        })
        .catch(e => {
            if (e.message.indexOf('NOT_FOUND') !== -1) return;
        });
};

/**
 * refresh the current auth token for another period of time
 * @returns {object} response data
 */
export const getTokenRefresh = async () => {
    return getData(apiEndpoints.REACT_APP_REFRESH_TOKEN_URL, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getTokenRefresh() error refreshing auth token - code: GT TKN RFRSH',
            error: e,
            eventId: 'GT_TKN_RFRSH',
        });

        forceLogout();
    });
};

/**
 * get texts in a specific language
 * @param {string} lang language to get
 * @return {object} texts in specific language
 */

export const getLanguageFile = async (lang, type) => {
    try {
        let defaultFile = `/texts/${lang}/dispatcher.json`;
        if (type === 'caller') {
            defaultFile = `/texts/${lang}/caller.json`;
        }
        if (type === 'conference') {
            defaultFile = `/texts/${lang}/conference.json`;
        }

        const defaultTexts = await getData(defaultFile, oauthStore.authToken, 'text');

        return { ...JSON.parse(defaultTexts) };
    } catch (e) {
        errorLog({
            message: 'getLanguageFile() error getting ' + lang + ' - code: GT LNG ',
            error: e,
            eventId: 'GT_LNG',
        });
    }
};

// CALLER ONLY
/**
 * invalidate the current token data when logging out
 * @returns {object} response data
 */
export const postLogout = async () => {
    return postData(apiEndpoints.REACT_APP_LOGOUT_TOKEN_ENDPOINT, {}, oauthStore.authToken, false).catch(e => {
        errorLog({
            message: 'postLogout() error invalidating auth token - code: PST LGT',
            error: e,
            eventId: 'PST_LGT',
        });
    });
};

/**
 * sends a sms with the location data givin
 * @param {object} data location data and phonenumber
 * @returns {object} response data
 */
export const sendGEOSMSAPI = async data => {
    const additionalStates = {
        0: obscurePhonenumber(data.phone),
    };

    createKpiLog('infoGeoSmsSent', '', additionalStates);

    let response = await postData(apiEndpoints.REACT_APP_SEND_GEO_SMS_URL, data, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'sendGEOSMSAPI() error posting data to backend - code: SND GEO SMS',
            error: e,
            eventId: 'SEND_GEO_SMS',
        });
        addNotificationAndShowDispatch('error.snd_geo_sms', 'error');
        throw new Error('smsError');
    });
    return response;
};

/**
 * sends a sms with invitation message and link
 * @param {object} data location data and phonenumber
 * @returns {object} response data
 */
export const sendInvitationSms = async data => {
    createKpiLog('infoInviteSmsSent');

    let response = await postData(apiEndpoints.REACT_APP_SEND_INVITATION_SMS, data, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'sendInvitationSms() error posting data to backend - code: SND INVIT SMS',
            error: e,
            eventId: 'SEND_INVITE_SMS',
        });
        addNotificationAndShowDispatch('error.snd_invit_sms', 'error');
        throw new Error('smsError');
    });
    return response;
};

/**
 * get all application features available for the current user
 * @returns {array} enabled features
 */
export const getApplicationFeaturesDispatcher = async () => {
    const config = await getData(apiEndpoints.REACT_APP_APPLICATION_DISPATCHER, oauthStore.authToken).catch(e => {
        if (DEBUG) addLogDispatch(['getApplicationFeaturesDispatcher', e]);
    });

    if (config) {
        return config.enabledFeatures;
    }
    return [];
};

/**
 * generate a pdf for the session log
 */
export const createSessionReport = async ({ data }, sessionId) => {
    const additionalStates = {
        0: `session_${dispatcherWebRTCManager.webRtcSessionId}_${sessionId}.pdf`,
        sessionId,
    };

    createKpiLog('infoPdfReportCreated', '', additionalStates);

    return await postData(apiEndpoints.REACT_APP_SESSION_LOG_PDF, data, oauthStore.authToken, false).catch(e => {
        if (DEBUG) addLogDispatch(['createSessionReport', e]);
        errorLog({
            message: 'createSessionReport() error - code: GNRT_SSSN_LG',
            error: e,
            eventId: 'GNRT_SSSN_LG',
        });
        addNotificationAndShowDispatch('error.gnrt_sssn_lg', 'error');

        disableSessionDownloadDispatch();
    });
};

/**
 * get pdf data from given url
 */

export const getSessionReport = async url => {
    return await getData(url, oauthStore.authToken, 'blob').catch(e => {
        if (DEBUG) addLogDispatch(['getSessionReport', e]);
        errorLog({
            message: 'getSessionReport() error - code: GET_SSSN_LG',
            error: e,
            eventId: 'GET_SSSN_LG',
        });
        addNotificationAndShowDispatch('error.get_sssn_lg', 'error');

        disableSessionDownloadDispatch();
    });
};

/**
 * publish a chat message to the backend
 */

export const publishChatMessage = async ({ message, translation, from, sessionId }) => {
    const transformedData = {
        message,
        source: from,
        sessionId: dispatcherAuthManager.sessionId,
    };

    await postData(apiEndpoints.REACT_APP_PUBLISH_CHAT_MESSAGE_ENDPOINT, transformedData, oauthStore.authToken, false).catch(e => {
        if (DEBUG) addLogDispatch(['publishChatMessage', e]);
        errorLog({
            message: 'publishChatMessage() error - code: PSH CHT MSG',
            error: e,
            eventId: 'PSH_CHT_MSG',
        });
        addNotificationAndShowDispatch('error.psh_cht_msg', 'error');
    });
};

/**
 * upload a image to the backend
 */

export const uploadImage = async ({ image, type }) => {
    const formData = new FormData();

    let file = convertBase64ToBlob(image.replace('application/octet-stream', type));

    formData.append('file', file);
    formData.append('sessionId', dispatcherAuthManager.sessionId);

    const params = {
        method: 'POST',
        withCredentials: true,
        body: formData,
        headers: {
            Authorization: `Bearer ${oauthStore.authToken}`,
        },
    };

    await fetch(apiEndpoints.REACT_APP_UPLOAD_FILE_ENDPOINT, params).catch(e => {
        if (DEBUG) addLogDispatch(['uploadImage error', e]);
        errorLog({
            message: 'uploadImage() error - code: UPLD_IMG',
            error: e,
            eventId: 'UPLD_IMG',
        });
    });
};

// CALLER ONLY
/**
 * gets the authoken for a given session id
 * @param {string} sessionId current session id to get the toekn for
 * @returns {string} auth token
 */
export const getAuthtoken = async sessionId => {
    const token = await getData(apiEndpoints.REACT_APP_RESOLVE_TOKEN_URL + sessionId)
        .then(response => {
            return response.token;
        })
        .catch(e => {
            if (e.message.indexOf('NOT_FOUND') !== -1) return;
            errorLog({
                message: 'getAuthtoken() error getting data from backend - code: TKN RQST',
                sessionId: sessionId,
                error: e,
                eventId: 'TOKEN_REQUEST',
            });
        });

    if (token) {
        if (DEBUG) addLogDispatch(['getAuthtoken', token]);
        return token;
    }
};
/**
 * gets the webRtcAlias for a given session id
 * @param {string} sessionId current session id to get the toekn for
 * @returns {string} auth token
 */
export const getWebRtcConnectionProperties = async sessionId => {
    const webRtcConnectionProperties = await getData(apiEndpoints.REACT_APP_RESOLVE_TOKEN_URL + sessionId)
        .then(response => {
            const connectionProperties = { webRtcToken: response.webRtcToken, roomId: response.roomId, chatThreadId: response.chatThreadId };
            return connectionProperties;
        })
        .catch(e => {
            if (e.message.indexOf('NOT_FOUND') !== -1) return;
            errorLog({
                message: 'getAuthtoken() error getting data from backend - code: TKN RQST',
                sessionId: sessionId,
                error: e,
                eventId: 'TOKEN_REQUEST',
            });
        });

    if (webRtcConnectionProperties) {
        return webRtcConnectionProperties;
    }

    return null;
};

// CALLER ONLY
/**
 * sends a new location to the backend
 * @param {object} location new location data
 * @returns {object} response data
 */
export const sendNewLocation = async location => {
    const token = callerWebRTCManager.authToken;
    const { sessionId } = getURLParams();
    await postData(apiEndpoints.REACT_APP_NEWLOCATION_URL, location, token, false, false).catch(e => {
        errorLog({
            message: 'sendingLocations() error getting data from backend. - code: GEO SEND',
            error: e,
            sessionId: sessionId,
            eventId: 'GEO_SEND',
        });
    });
};

// CALLER ONLY
/**
 * gets the session end messages
 * @returns {array} session end messages
 */
export const getSessionEndMessages = async token => {
    const { sessionId } = getURLParams();
    const results = await getData(apiEndpoints.REACT_APP_SESSIONEND_URL, token, 'json', false).catch(e => {
        errorLog({
            message: 'getSessionEndMessages() error getting data from backend. - code: SSSN ND',
            error: e,
            sessionId: sessionId,
            eventId: 'SESSION_END',
        });
    });
    if (results && results[0]) {
        if (DEBUG) addLogDispatch(['getSessionEndMessages', results[0]]);
        return results[0].split('\n');
    }
};

// CONFERENCE USERS ONLY
/**
 * gets the session end messages
 * @returns {array} session end messages
 */
export const getSessionEndMessagesConference = async token => {
    const { sessionId } = getURLParams();
    const results = await getData(apiEndpoints.REACT_APP_SESSIONENDCONFERENCE_URL, token, 'text', false).catch(e => {
        errorLog({
            message: 'getSessionEndMessagesConference() error getting data from backend. - code: SSSN ND',
            error: e,
            sessionId: sessionId,
            eventId: 'SESSION_END_CONFERENCE',
        });
    });
    if (results && results[0]) {
        if (DEBUG) addLogDispatch(['getSessionEndMessages', results.split()]);
        return results.split();
    }
    return '';
};

/**
 * get the application features available for the current user
 * @returns {object} application config
 */
export const getApplicationFeaturesConference = async () => {
    const config = await getData(apiEndpoints.REACT_APP_APPLICATION_CALLER, invitedUserWebRTCManager.authToken, 'json', false).catch(e => {
        if (DEBUG) addLogDispatch(['getApplicationFeaturesCaller', e]);
    });

    if (config) {
        return config.enabledFeatures;
    }
    return [];
};

// CALLER ONLY
/**
 * consume the short token after receiving a auth token
 * @returns {object} response data
 */
export const postShortTokenComsumed = async () => {
    const { sessionId } = getURLParams();
    return postData(apiEndpoints.REACT_APP_SHORT_TOKEN_CONSUMED + sessionId, {}, callerWebRTCManager.authToken, false)
        .then(response => {
            return response;
        })
        .catch(e => {
            if (e.message.indexOf('NOT_FOUND') !== -1) return;
            if (!isCaller) addNotificationAndShowDispatch('ERROR !!!!', 'error');
        });
};

// CALLER ONLY
/**
 * Gets the chat labels in a specific language
 * @param {string} lang language to get
 */
export const getChatLabels = async lang => {
    try {
        const defaultFile = `/texts/${lang}/chat.json`;

        const defaultTexts = await getData(defaultFile, oauthStore.authToken, 'text');

        return { ...JSON.parse(defaultTexts) };
    } catch (e) {
        errorLog({
            message: 'getChatLabels() error getting ' + lang + ' - code: GT CHT LBL',
            error: e,
            eventId: 'GT_CHT_LBL',
        });
    }
};

// CALLER ONLY
/**
 * get the application features available for the current user
 * @returns {object} application config
 */
export const getApplicationFeaturesCaller = async () => {
    const config = await getData(apiEndpoints.REACT_APP_APPLICATION_CALLER, callerWebRTCManager.authToken, 'json', false).catch(e => {
        if (DEBUG) addLogDispatch(['getApplicationFeaturesCaller', e]);
    });

    if (config) {
        return config.enabledFeatures;
    }
    return [];
};

/**
 * get the saved language for the current dispatch user
 * @returns {string} language handle
 */

export const getLanguageForDispatcher = async () => {
    return await getData(apiEndpoints.REACT_APP_LANGUAGE_GET, oauthStore.authToken, 'text').catch(e => {
        if (DEBUG) addLogDispatch(['getLanguageForDispatcher', e]);
        errorLog({
            message: 'getLanguageForDispatcher() error getting data from backend. - code: GT LNGG',
            error: e,
            eventId: 'GET_LANGUAGE',
        });
    });
};

/**
 * sets the language for the current dispatch user
 * @param {string} languageHandle language handle
 */

export const setLanguageForDispatcher = async languageHandle => {
    return await postData(apiEndpoints.REACT_APP_LANGUAGE_SET, { language: languageHandle }, oauthStore.authToken, false).catch(e => {
        if (DEBUG) addLogDispatch(['setLanguageForDispatcher', e]);
        errorLog({
            message: 'setLanguageForDispatcher() error setting data. - code: ST LNGG',
            error: e,
            eventId: 'SET_LANGUAGE',
        });
    });
};

/**
 * fetch an image from backend
 * @returns {FormData} image
 */
let imageEndpoint = null;
export const getImage = async ({ type = 'blob', imageType }, token) => {
    if (imageType === imageEndpointType.LOGO) {
        imageEndpoint = apiEndpoints.REACT_APP_IMAGE_DISPLAY_ENDPOINT;
    } else if (imageType === imageEndpointType.CONFERENCE_LOGO) {
        imageEndpoint = apiEndpoints.REACT_APP_CONFERENCE_LOGO_ENDPOINT;
    } else if (imageType === imageEndpointType.VIDEO_BACKGROUND) {
        imageEndpoint = apiEndpoints.REACT_APP_BACKGROUND_IMAGE_GET;
    } else {
        return;
    }

    let authToken = '';
    if (token) {
        authToken = token;
    } else {
        authToken = oauthStore.authToken || callerWebRTCManager.authToken;
    }

    const params = {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    };

    const image = await fetch(imageEndpoint, params).catch(e => {
        console.log(e);
        if (DEBUG) addLogDispatch(['getImage', e]);
    });

    if (image && image.ok) {
        const imageData = await image.blob();

        switch (true) {
            case type === 'base64':
                return await convertBlobToBase64(imageData);
            case type === 'blob':
            default:
                return URL.createObjectURL(imageData);
        }
    }
};

/**
 * get all the contacts available for the current user
 * @returns {array} enabled features
 */
export const getContactsDispatcher = async () => {
    const contacts = await getData(apiEndpoints.REACT_APP_CONTACTS_ENDPOINT, oauthStore.authToken).catch(e => {
        if (DEBUG) addLogDispatch(['getContactsDispatcher', e]);
    });

    if (contacts) {
        return contacts;
    }
    return [];
};

/**
 * get the mimetype for the snapshot function
 * @returns {string|null} mimetype
 */

export const getSnapshotMimeType = async () => {
    const mimeType = await getData(apiEndpoints.REACT_APP_MIMETYPE_ENDPOINT, oauthStore.authToken, 'text').catch(e => {
        if (DEBUG) addLogDispatch('getSnapshotMimeType', e);
    });

    if (mimeType) {
        return mimeType;
    }
    return IMAGE_FORMAT;
};

/**
 * convert an image to a certain format
 * @param {object} image
 * @param {string} targetFormat
 * @returns {object} convertedImage
 */

export const convertImageToFormat = async (image, targetFormat) => {
    const formData = new FormData();

    const file = convertBase64ToBlob(image.image);

    formData.append('file', file);
    formData.append('targetFormat', targetFormat);

    const params = {
        method: 'POST',
        withCredentials: true,
        body: formData,
        headers: {
            Authorization: `Bearer ${oauthStore.authToken}`,
        },
    };

    const result = await fetch(apiEndpoints.REACT_APP_IMAGE_CONVERT_ENDPOINT, params);
    const convertedImage = await result.blob();
    const target = IMAGE_TARGET[targetFormat];
    return {
        image: await convertBlobToBase64(convertedImage),
        time: Date.now(),
        type: `image/${target}`,
    };
};

/**
 * convert an image to a certain format
 * @param {object} image
 * @param {string} targetFormat
 * @returns {object} convertedImage
 */

export const overlayTwoImages = async (backImage, overlayImage) => {
    const formData = new FormData();

    const backFile = convertBase64ToBlob(backImage);
    const overlayFile = convertBase64ToBlob(overlayImage);

    formData.append('file', backFile);
    formData.append('overlay', overlayFile);

    const params = {
        method: 'POST',
        withCredentials: true,
        body: formData,
        headers: {
            Authorization: `Bearer ${oauthStore.authToken}`,
        },
    };

    const result = await fetch(apiEndpoints.REACT_APP_IMAGE_OVERLAY_ENDPOINT, params);
    const mergedImage = await result.blob();

    return {
        image: await convertBlobToBase64(mergedImage),
        time: Date.now(),
        type: `image/png`,
    };
};

// DISPATCHER ONLY
/**
 * send an email from specified address to recipient
 * @returns {object} response data
 */
export const postSendEmail = async ({ to, subject, message }) => {
    return postData(
        apiEndpoints.REACT_APP_EMAIL_ENDPOINT,
        {
            to,
            subject,
            message,
        },
        oauthStore.authToken,
        false
    )
        .then(() => {
            return true;
        })
        .catch(e => {
            //TODO: Need error message translation for failed email
            // addNotificationAndShowDispatch(e.status, 'error');
            console.log(e);

            // TODO: Send message to graylog
            throw new Error();
        });
};

/**
 * get current dispatch center id
 * @returns {array} dispatch center id
 */
export const getCurrentDispatchCenterId = async () => {
    const config = await getData(apiEndpoints.REACT_APP_APPLICATION_DISPATCHER, oauthStore.authToken).catch(e => {
        if (DEBUG) addLogDispatch(['getCurrentDispatchCenterId', e]);
    });

    if (config) {
        return config.dispatchCenter.id;
    }
    return [];
};

/**
 * get invite email text for current dispatch center
 * @returns
 */
export const getCurrentInviteEmailText = async () => {
    return await getData(apiEndpoints.REACT_APP_INVITE_EMAIL_TEXT, oauthStore.authToken, 'text').catch(e => {
        if (DEBUG) addLogDispatch(['getCurrentInviteEmailText', e]);
        errorLog({
            message: 'getCurrentInviteEmailText() error getting data from backend. - code: GT CIET',
            error: e,
            eventId: 'GET_EMAIL_TEXT',
        });
    });
};

/**
 * send an invite from specified address to recipient
 * @returns {object} response data
 */
export const sendInvitationEmail = async ({ to, message, conference }) => {
    return postData(
        apiEndpoints.REACT_APP_INVITE,
        {
            to,
            message,
            conference,
        },
        oauthStore.authToken,
        false
    );
};

/**
 * get apizee api key from backend
 * @returns {object} response object
 */
export const getApizeeKey = async (type, platform) => {
    let tokenType = null;
    let rtcApi = '';

    if (type === WebRtcManagerType.DISPATCHER) {
        tokenType = oauthStore.authToken;
    } else if (type === WebRtcManagerType.CALLER) {
        tokenType = callerWebRTCManager.authToken;
    } else if (type === WebRtcManagerType.INVITED_USER) {
        tokenType = invitedUserWebRTCManager.authToken;
    }

    if (platform && platform === 'hds') rtcApi = apiEndpoints.REACT_APP_RTC_PRIMARY_APIKEY_ENDPOINT;
    if (platform && platform === 'cloud') rtcApi = apiEndpoints.REACT_APP_RTC_SECONDARY_APIKEY_ENDPOINT;

    if (tokenType !== null) {
        const key = await getData(rtcApi, tokenType, 'text').catch(e => {
            errorLog({
                message: 'getApizeeKey() error getting data from backend - code: GT APIZKEY',
                error: e,
                eventId: 'GET_APIZKEY',
            });

            throw new Error();
        });
        if (key) {
            return key;
        }
    }
};

/**
 * get config for current dispatch center
 * @returns
 */
export const getCurrentDispatchCenterConfig = async () => {
    return await getData(apiEndpoints.REACT_APP_DISPATCHCENTER_CONFIGURATION_GET, oauthStore.authToken).catch(e => {
        if (DEBUG) addLogDispatch(['getCurrentDispatchCenterConfig', e]);
        errorLog({
            message: 'getCurrentDispatchCenterConfig() error getting data from backend. - code: GT CDCCC',
            error: e,
            eventId: 'GET_DISPATCH_CENTER_CONFIG',
        });
    });
};

/**
 * post new stream recording to backend
 * @returns
 */
export const postNewRecording = async (mediaId, mediaUrl, recordedFileName) => {
    return postData(
        apiEndpoints.REACT_APP_NEW_RECORDING_ENDPOINT,
        {
            mediaId,
            mediaUrl,
            recordedFileName,
        },
        oauthStore.authToken,
        false
    )
        .then(() => {
            return true;
        })
        .catch(e => {
            //TODO: Need error message translation for failed recording post request
            console.log(e);

            // TODO: Send message to graylog
            throw new Error();
        });
};

/**
 * post web rtc alias to backend
 * @returns
 */
export const postWebRtcAlias = async alias => {
    return postData(
        apiEndpoints.REACT_APP_DISPATCHER_WEBRTC_ALIAS,
        {
            webRtcAlias: alias,
        },
        oauthStore.authToken,
        false
    )
        .then(() => {
            return true;
        })
        .catch(e => {
            console.log(e);
            throw new Error();
        });
};

/**
 * post web rtc alias to backend
 * @returns
 */
export const postGroupCallId = async callId => {
    return postData(
        process.env.REACT_APP_DISPATCHER_GROUP_CALL_ID_POST,
        {
            callId: callId,
        },
        oauthStore.authToken,
        false
    )
        .then(() => {
            return true;
        })
        .catch(e => {
            console.log(e);
            throw new Error();
        });
};

/*
 * post external stream name to backend
 */
export const postExternalStream = async () => {
    let streamName = dispatcherAuthManager.bystanderToken.slice(dispatcherAuthManager.bystanderToken.length - 3, dispatcherAuthManager.bystanderToken.length);
    let streamUrl = apiEndpoints.REACT_APP_EXTERNAL_STREAM_ENDPOINT + streamName;

    return postData(streamUrl, {}, oauthStore.authToken, false)
        .then(response => {
            return response.ok;
        })
        .catch(e => {
            console.log(e);
            addNotificationAndShowDispatch('error.strm.svce', 'error', DISPLAY_ONLY_IN_SESSION);
            deactivateExternalStreamDispatch();
            dispatcherWebRTCManager.sendToggleExternalStreaming(false);
            throw new Error();
        });
};

/**
 * upload a image to the backend
 */

export const sendFileTransferInfo = async (file, timeToLive) => {
    let timestamp = Math.floor(Date.now() / 1000);
    let validTo = timestamp + timeToLive;

    const { sessionId } = getURLParams();

    let data = {
        timestamp,
        url: file.url,
        size: file.size,
        validTo,
        sessionId: sessionId,
    };

    const params = {
        method: 'POST',
        withCredentials: true,
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oauthStore.authToken}`,
        },
    };

    await fetch(apiEndpoints.REACT_APP_NEW_FILE_TRANSFER, params).catch(e => {
        if (DEBUG) addLogDispatch(['sendFileTransferInfo error', e]);
        errorLog({
            message: 'sendFileTransferInfo() error - code: FILE_TRNS_INFO',
            error: e,
            eventId: 'FILE_TRNS_INFO',
        });
    });
};

/**
 * send session end information to the backend
 */

export const sendSessionEndInfo = async ({ duration }) => {
    const transformedData = {
        duration,
        sessionId: dispatcherAuthManager.sessionId,
    };

    await postData(apiEndpoints.REACT_APP_SEND_SESSION_DURATION, transformedData, oauthStore.authToken, false).catch(e => {
        if (DEBUG) addLogDispatch(['sendSessionEndInfo', e]);
        errorLog({
            message: 'sendSessionEndInfo() error - code: SND SSN END',
            error: e,
            eventId: 'SND_SSN_END',
        });
    });
};

/*
 * delete external stream name from backend
 */
export const deleteExternalStream = async () => {
    let streamName = dispatcherAuthManager.bystanderToken.slice(dispatcherAuthManager.bystanderToken.length - 3, dispatcherAuthManager.bystanderToken.length);
    let streamUrl = apiEndpoints.REACT_APP_EXTERNAL_STREAM_ENDPOINT + streamName;

    return deleteData(streamUrl, oauthStore.authToken)
        .then(() => {
            return true;
        })
        .catch(e => {
            if (DEBUG) addLogDispatch(['deleteExternalStream', e]);
        });
};
