import { createListenerMiddleware } from '@reduxjs/toolkit';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { CallClient } from '@azure/communication-calling';
import store from '../store';
import { setWebRtcToken } from '../slices/webRtc/authSlice';
import { setRoomId } from '../slices/webRtc/roomSlice';
import { setChatThreadId, setChatThreadIsConnected } from '../slices/webRtc/chatThreadSlice';
import { ChatClient } from '@azure/communication-chat';
import { handleChatMessage } from '../../webrtc/authManagement/webRtcAuth';
import { addComplexObject } from '../../helper/complexObjectStorage';
import { WebRtcKeys } from '../../types/webRtc.types';

const webRtcListenerMiddleware = createListenerMiddleware();

webRtcListenerMiddleware.startListening({
    actionCreator: setWebRtcToken,
    effect: async (action) => {
        const token = action.payload;
        const credential = new AzureCommunicationTokenCredential(token);

        if (credential) {
            addComplexObject(WebRtcKeys.TOKEN_CREDENTIAL, credential);
        }
    },
});

webRtcListenerMiddleware.startListening({
    actionCreator: setRoomId,
    effect: async (action) => {
        const roomId = action.payload;
        const token = store.getState().webRtc.authSlice.token;

        if (roomId && token) {
            const credential = new AzureCommunicationTokenCredential(token);
            const callClient = new CallClient();
            const callAgent = await callClient.createCallAgent(credential);

            if (credential && callClient && callAgent) {
                addComplexObject(WebRtcKeys.CALL_CLIENT, callClient);
                addComplexObject(WebRtcKeys.CALL_AGENT, callAgent);
            }
        }
    },
});

webRtcListenerMiddleware.startListening({
    actionCreator: setChatThreadId,
    effect: async action => {
        const chatThreadId = action.payload;
        const token = store.getState().webRtc.authSlice.token;

        if (chatThreadId !== null && token !== null) {
            const credential = new AzureCommunicationTokenCredential(token);
            const chatClient = new ChatClient(process.env.REACT_APP_ACS_ENDPOINT, credential);
            const chatThreadClient = chatClient.getChatThreadClient(chatThreadId);

            if (chatClient) {
                addComplexObject(WebRtcKeys.CALL_CLIENT, chatClient);
                await chatClient.startRealtimeNotifications();
                store.dispatch(setChatThreadIsConnected(true));

                chatClient.on('chatMessageReceived', async e => {
                    handleChatMessage(e);
                });
            }

            if (chatThreadClient) {
                addComplexObject(WebRtcKeys.CHAT_THREAD_CLIENT, chatThreadClient);
            }
        }
    },
});

export default webRtcListenerMiddleware;
