import { endpointKeys } from './endpointKeys';
import { systemConfig } from './systemConfigBuilder';

const getBaseUrl = () => {
    const baseUrl = systemConfig.REACT_APP_BASE_URL;

    if (baseUrl) {
        return baseUrl;
    }

    return `${window.location.protocol}//${window.location.host}`; // Use URL params as fallback
};

const baseUrl = getBaseUrl();

const createApiEndpoint = key => {
    return process.env.REACT_APP_USE_ENDPOINT_BUILDER === 'true' ? replacePlaceholders(process.env[key]) : process.env[key];
};

const replacePlaceholders = url => {
    return url?.replace(/{BASE_URL}/g, baseUrl);
};

// Dynamic exports
const apiEndpoints = Object.fromEntries(endpointKeys.map(key => [key, createApiEndpoint(key)]));

export { apiEndpoints };
