import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './SessionDownload.scss';
import { SESSION_DOWNLOAD_TIMEOUT, SESSION_DOWNLOAD_FILENAME } from '../../config';
import { disableSessionDownloadDispatch, resetSessionDispatch } from '../../redux/actions/session';
import Loading from '../Icons/LoadingSpinner';
import Download from '../Icons/Download';
import { createSessionReport, getSessionReport } from '../../api/backendApi';
import { convertSvgToPng, createKpiLog, replaceText, toMatchingTimeUnit } from '../../helper/helper';

/**
 * SessionDownload
 * handles the download of the last session log. Allowed only for a specific time frame e.g. 5 minutes.
 *
 * @component Loading
 */
class SessionDownload extends Component {
    _timerInterval = null;
    loadInterval = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            timer: SESSION_DOWNLOAD_TIMEOUT,
            loading: false,
            pdfBlob: null,
            blinkAnimationFinished: false,
        };
    }

    componentDidMount() {
        this.uploadSessionReport();
        if (this.props.allowSessionDownload) {
            this.startDownloadTimer();
        }
        setTimeout(() => {
            this.setState({
                blinkAnimationFinished: true,
            });
        }, 5000);
    }

    componentWillUnmount() {
        this.stopDownloadTimer();
    }

    startDownloadTimer = () => {
        this._timerInterval = setInterval(this.timerChange, 1000);
    };

    timerChange = () => {
        this.setState({ timer: this.state.timer - 1000 });
        if (this.state.timer <= 0) {
            this.stopDownloadTimer();
            resetSessionDispatch();
        }
    };

    stopDownloadTimer = () => {
        clearInterval(this._timerInterval);
    };

    generateBlob = () => {
        let blob = this.state.pdfBlob;

        if (blob && blob.size && blob.size > 3) {
            clearInterval(this.loadInterval);
            this.proceedToDownload(blob);
        }
    };

    proceedToDownload = blob => {
        this.downloadPDF(blob);

        // DOWNLOAD FINISHES
        this.setState({ loading: false });
        this.startDownloadTimer();
    };

    startDownload = () => {
        if (!this.props.allowSessionDownload) return;

        this.stopDownloadTimer();
        this.setState({ loading: true });

        const loadpdfBlobForDownloading = () => {
            try {
                this.loadInterval = setInterval(this.generateBlob, 3000);
            } catch {
                disableSessionDownloadDispatch();
                this.setState({ loading: false });
            }
        };

        loadpdfBlobForDownloading();
    };

    downloadPDF = blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        const fileName = `${SESSION_DOWNLOAD_FILENAME}_${this.props.sessionData.userId}_${this.props.sessionData.sessionId}.pdf`;

        const additionalStates = {
            0: fileName,
            sessionId: this.props.sessionData.sessionId,
        };

        createKpiLog('infoSessionReportDownloaded', '', additionalStates);

        link.href = url;
        link.setAttribute('download', fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    uploadSessionReport = async () => {
        if (!this.props.allowSessionDownload) return;

        // create deep copy to avoid redux state mutation
        const data = JSON.parse(JSON.stringify(this.props.sessionData));

        const recordingDataWithThumbnailRemoved = data.recordings.map(recording => {
            const { thumbnail, ...rest } = recording;
            return rest;
        });

        data.recordings = recordingDataWithThumbnailRemoved;

        data.whiteLabeling.logo = await convertSvgToPng(data.whiteLabeling.logo);

        // remove notes from kpi log table before sending to backend
        data.logs = data.logs.filter(entry => entry.eventId !== 'SESSION_NOTES');

        // timeToLive converted from seconds to time reflected in days / months
        data.timeToLive = toMatchingTimeUnit(data.timeToLive);

        const pdfResponse = await createSessionReport({ data }, this.props.sessionData.sessionId).catch(e => {
            console.info('Error with session report creation: ', e);
        });

        if (pdfResponse) {
            const pdfLink = await pdfResponse.text();
            const pdfBlob = await getSessionReport(pdfLink).catch(e => {
                console.info('Error with session report download: ', e);
            });

            this.setState({ pdfBlob });
        }
    };

    render() {
        const timer = this.state.timer / 1000;
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;

        let classes = `btn btn--primary btn--icon ${this.state.blinkAnimationFinished ? '' : 'icon-animation'}`;

        return (
            <div className="session-download">
                {this.state.loading ? (
                    <div className="session-download__loading">
                        <span>{replaceText(this.props.texts, 'session.downloading')}</span> <Loading />
                    </div>
                ) : (
                    this.props.allowSessionDownload && (
                        <div>
                            <button className={classes} onClick={this.startDownload}>
                                <Download />
                                {replaceText(this.props.texts, 'session.download')}
                            </button>
                        </div>
                    )
                )}

                {this.props.allowSessionDownload && (
                    <span>
                        {minutes}:{seconds > 9 ? seconds : '0' + seconds}
                    </span>
                )}
            </div>
        );
    }
}

// PropTypes for this Component
SessionDownload.propTypes = {
    allowSessionDownload: PropTypes.bool,
    sessionData: PropTypes.object,
    texts: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        allowSessionDownload: state.session.downloadable,
        sessionData: state.session,
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(SessionDownload);
