export const systemConfigKeys = [
    'REACT_APP_APP_CLIENT_ID',
    'REACT_APP_BASE_URL',
    'REACT_APP_THEME',
    'REACT_APP_TITLE',
    'REACT_APP_IMAGE_NAME',
    'REACT_APP_LANGUAGE',
    'REACT_APP_PLACEHOLDER_COUNTRY_CODE',
    'REACT_APP_SEND_SMS',
    'REACT_APP_SHOW_SMS_CHECKBOX',
    'REACT_APP_SHOW_DEV_LINKS',
    'REACT_APP_DEBUG',
    'REACT_APP_CUSTOM_HELPDESK',
];
