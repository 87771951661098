import { DEFAULT_LANGUAGE, IMAGE_FORMAT, SESSION_DOWNLOAD_LOG_FILTER } from '../../config';
import {
    ADD_SESSION_CHATMESSAGE,
    ADD_SESSION_GPS,
    ADD_SESSION_IMAGE,
    ADD_SESSION_INFOS,
    ADD_SESSION_LANGUAGE,
    ADD_SESSION_LOG,
    ADD_SESSION_MAP,
    ADD_SESSION_WHITELABEL_LOGO,
    ALLOW_PHOTO_PERMISSION,
    CHANGE_SESSION_IMAGE_FORMAT,
    DISABLE_SESSION_DOWNLOAD,
    DISALLOW_PHOTO_PERMISSION,
    RESET_SESSION,
    START_SESSION,
    STOP_SESSION,
    ADD_SESSION_NOTES,
    ADD_SESSION_RECORDING,
    ADD_SESSION_FILE,
    UPDATE_SESSION_PHONENUMBER,
    ADD_VIDEO_BACKGROUND_IMAGE,
    REMOVE_VIDEO_BACKGROUND_IMAGE,
    ADD_TTL,
    ADD_SESSION_CHAT_HISTORY,
    ADD_CALLER_CONNECTION_QUALITY,
} from '../actions/actionTypes';
import { extractTimestampFromFilename } from '../../helper/helper';
import { FILE_TYPE } from '../../types';
import { systemConfig } from '../../helper/systemConfigBuilder';
import { cssVariables, logo } from '../../helper/themeLoader';

export const initialState = {
    whiteLabeling: {
        logo: logo,
        color: cssVariables.primary,
        title: systemConfig.REACT_APP_TITLE,
    },
    userId: '',
    sessionId: '',
    dispatchCenter: '',
    user: '',
    phone: '',
    sessionStart: '',
    sessionEnd: '',
    geoMap: null,
    osName: '',
    osVersion: '',
    connectionQuality: '',
    browser: '',
    long: '',
    lat: '',
    accuracy: '',
    images: [],
    imageFormat: IMAGE_FORMAT,
    recordings: [],
    fileUploads: [],
    chatHistory: [],
    notes: '',
    logs: [],
    timeToLive: null,
    sessionOpen: false,
    downloadable: false,
    language: DEFAULT_LANGUAGE,
    photoPermission: false,
    videoBackgroundImage: '',
};

/**
 * session reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const logsReducer = (state = initialState, action) => {
    // STARTS WHEN CALLER ACCEPTS INVITATION
    if (action.type === START_SESSION) {
        return {
            ...state,
            sessionStart: action.start,
            userId: action.userId,
            sessionId: action.sessionId,
            dispatchCenter: action.dispatchCenter,
            user: decodeURIComponent(action.user),
            phone: action.phone,
            sessionOpen: true,
        };
    }

    if (action.type === ADD_SESSION_LOG) {
        if (state.sessionOpen) {
            if (SESSION_DOWNLOAD_LOG_FILTER.indexOf(action.entry.eventId) === -1) {
                return { ...state, logs: [...state.logs, action.entry] };
            }
        }
    }

    if (action.type === ADD_SESSION_CHATMESSAGE) {
        return {
            ...state,
            chatHistory: [...state.chatHistory, action.entry],
        };
    }

    if (action.type === ADD_SESSION_CHAT_HISTORY) {
        return {
            ...state,
            chatHistory: [...action.history],
        };
    }

    if (action.type === ADD_SESSION_IMAGE) {
        return { ...state, images: [...state.images, action.image] };
    }

    if (action.type === ADD_SESSION_RECORDING) {
        // Extract timestamp from filename
        let extractedTimeStamp = Number(extractTimestampFromFilename(action.recordingInfo));
        let recordingInfoAlreadyExistsInArray = false;
        let recordingType = action.image !== 'data:,' ? FILE_TYPE.VIDEO : FILE_TYPE.AUDIO;

        let recordings = state.recordings.map(recordingInfo => {
            // Add thumbnail and updated status to array element in which filenames of action and existing recordingInfo match
            if (recordingInfo.recording.recordedFileName === action.recordingInfo.recordedFileName) {
                recordingInfoAlreadyExistsInArray = true;
                return { ...recordingInfo, thumbnail: action.image, status: action.status, type: recordingType };
            }
            return recordingInfo;
        });

        // Add new recordingInfo to recordings state array if none exist
        if (recordings.length === 0) {
            return {
                ...state,
                recordings: [
                    ...state.recordings,
                    {
                        recording: action.recordingInfo,
                        thumbnail: '',
                        created: extractedTimeStamp,
                        status: action.status,
                        filename: action.recordingInfo.recordedFileName,
                        url: action.recordingInfo.mediaURL,
                    },
                ],
            };
        } else if (recordings.length !== 0 && !recordingInfoAlreadyExistsInArray) {
            // If recordings array is not empty and recording info does not already exist in state array, add it
            return {
                ...state,
                recordings: [
                    ...recordings,
                    {
                        recording: action.recordingInfo,
                        thumbnail: action.image,
                        created: extractedTimeStamp,
                        status: action.status,
                        filename: action.recordingInfo.recordedFileName,
                        url: action.recordingInfo.mediaURL,
                    },
                ],
            };
        } else {
            // If recording info already exists in array, return recordings state (do nothing)
            return { ...state, recordings: [...recordings] };
        }
    }

    if (action.type === ADD_SESSION_FILE) {
        return {
            ...state,
            fileUploads: [
                ...state.fileUploads,
                {
                    filename: action.filename,
                    type: action.fileType,
                    created: action.created,
                    url: action.url,
                },
            ],
        };
    }

    if (action.type === CHANGE_SESSION_IMAGE_FORMAT) {
        return { ...state, imageFormat: action.imageFormat };
    }

    if (action.type === ADD_SESSION_MAP) {
        return { ...state, geoMap: action.map };
    }

    if (action.type === ADD_SESSION_INFOS) {
        return {
            ...state,
            browser: action.browser,
            osName: action.osName,
            osVersion: action.osVersion,
        };
    }

    if (action.type === ADD_SESSION_GPS) {
        return {
            ...state,
            long: action.long,
            lat: action.lat,
            accuracy: action.accuracy,
        };
    }

    if (action.type === ADD_SESSION_LANGUAGE) {
        return {
            ...state,
            language: action.lang,
        };
    }

    if (action.type === ADD_SESSION_NOTES) {
        return { ...state, notes: action.notes };
    }

    if (action.type === ADD_SESSION_WHITELABEL_LOGO) {
        return {
            ...state,
            whiteLabeling: {
                ...state.whiteLabeling,
                logo: action.logo,
            },
        };
    }

    // STOPS WHEN DISPATCHER CLOSES SESSION
    if (action.type === STOP_SESSION) {
        if (state.sessionOpen) {
            return {
                ...state,
                sessionEnd: action.end,
                sessionOpen: false,
                downloadable: true,
            };
        }
    }

    if (action.type === DISABLE_SESSION_DOWNLOAD) {
        return {
            ...state,
            downloadable: false,
        };
    }

    if (action.type === ALLOW_PHOTO_PERMISSION) {
        return {
            ...state,
            photoPermission: true,
        };
    }

    if (action.type === DISALLOW_PHOTO_PERMISSION) {
        return {
            ...state,
            photoPermission: false,
        };
    }

    if (action.type === UPDATE_SESSION_PHONENUMBER) {
        return {
            ...state,
            phone: action.phone,
        };
    }

    if (action.type === ADD_VIDEO_BACKGROUND_IMAGE) {
        return {
            ...state,
            videoBackgroundImage: action.image,
        };
    }

    if (action.type === REMOVE_VIDEO_BACKGROUND_IMAGE) {
        return {
            ...state,
            videoBackgroundImage: '',
        };
    }

    if (action.type === ADD_TTL) {
        return {
            ...state,
            timeToLive: action.milliseconds,
        };
    }

    if (action.type === ADD_CALLER_CONNECTION_QUALITY) {
        return {
            ...state,
            connectionQuality: action.rating,
        };
    }

    if (action.type === RESET_SESSION) {
        return { ...initialState };
    }

    return state;
};

export default logsReducer;
